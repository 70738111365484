import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import FormNianJing from '../views/Form/NianJing.vue';
import FormPoWa from '../views/Form/PoWa.vue';
import FormWuGong from '../views/Form/WuGong.vue';
import Payment from '../views/Payment/index.vue';
import Success from '../views/Success.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'payment',
    component: Payment,
  },
  {
    path: '/form/nianjing',
    name: 'form-nianjing',
    component: FormNianJing,
  },
  {
    path: '/form/powa',
    name: 'form-powa',
    component: FormPoWa,
  },
  {
    path: '/form/wugong',
    name: 'form-wugong',
    component: FormWuGong,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
