import { defineStore } from 'pinia';

type TypeList = 'nj' | 'pw' | 'wg';

export const useStore = defineStore('store', {
  state: () => ({
    showFirstImg: true,
    hasFirstImg: true,
    active: 0,

    formId: '',
    payMethod: '1',
    form: {
      name: '',
      phone: '',
      date: '',
      time: '',
      amount: '',
      method: '其他方式',
      extra: '',
    },

    nj: {
      isFinished: false,
      checked: '',
      checkList: [] as { text: string; canRemove: boolean }[],
      backContent: '',
      amount: '',
      repeat: '',
    },
    pw: {
      isFinished: false,
      soul: '',
      amount: '',
      repeat: '',
    },
    wg: {
      isFinished: false,
      use: '',
      amount: '',
      repeat: 1,
      backObjectList: [{ name: '' }],
    },
  }),
  actions: {
    getBalance(type?: TypeList): number {
      if (type === 'nj')
        return Number((Number(this.nj.amount) * Number(this.nj.repeat)).toFixed(2));
      if (type === 'pw')
        return Number((Number(this.pw.amount) * Number(this.pw.repeat)).toFixed(2));
      if (type === 'wg')
        return Number((Number(this.wg.amount) * Number(this.wg.repeat)).toFixed(2));
      return Number(
        (
          Number(this.nj.amount) * Number(this.nj.repeat) +
          Number(this.pw.amount) * Number(this.pw.repeat) +
          Number(this.wg.amount) * Number(this.wg.repeat)
        ).toFixed(2),
      );
      // let typeList: TypeList[] = ['nj', 'pw', 'wg'];
      // let totalUsed = 0;
      // typeList.forEach((v: TypeList) => {
      //   if (v === type) return;
      //   if (this[v].isFinished) totalUsed += Number(this[v].amount) * Number(this[v].repeat);
      // });
      // return Number((Number(this.form.amount) - totalUsed).toFixed(1));
    },
    clickRemoveNj(item: { text: string; canRemove: boolean }) {
      if (!item.canRemove) return;
      this.nj.checkList = this.nj.checkList.filter((v) => v.text !== item.text);
    },
    // updateConversation(conversation: Conversation) {
    //   this.conversation = conversation;
    // },
  },
  // persist: {
  //   enabled: true,
  // },
});
